<template>
  <div class="home-info">
    <div class="home-heder-banner">
      <a-carousel arrows>
        <div slot="prevArrow" class="custom-slick-arrow" style="left: 10px">
          <a-icon type="left-circle" />
        </div>
        <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
          <a-icon type="right-circle" />
        </div>
        <div><img style="width: 100%" src="@/assets/banner1.jpg" alt="" /></div>
      </a-carousel>
    </div>
    <PatentApplication
      :showtitle="true"
      :title="'专利申请服务'"
      :patentitle="patent_list"
      :patentlist="$store.state.home.patentlist.serviceList"
      :more="'更多专利服务'"
      :type="'patentlist'"
    />
    <div class="home-trade">
      <Title :title="title_list" :more="'热门专利交易'" />
      <div class="trade-tab">
        <a-tabs :default-active-key="0" @change="callback">
          <a-tab-pane
            v-for="(item, index) in tab_list"
            :key="index"
            :tab="item.title"
          >
            <div
              class="tab-item"
              v-for="(tab, i) in item.list"
              :key="i + 'tab'"
            >
              <img class="tab-img" src="@/assets/authorized.png" alt="" />
              <div class="header">
                <div class="title">{{ tab.title }}</div>
                <div class="text">{{ tab.patentText }}</div>
              </div>
              <div class="footer">
                <div class="pirce">
                  <span class="logo">￥</span>
                  <span class="num">{{ tab.price }}</span>
                </div>
                <div @click="showModel(tab)" class="btn">购买咨询</div>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <div class="home-trademark-register">
      <PatentApplication
        :showtitle="true"
        :patentlist="$store.state.home.trademark.serviceList"
        :patentitle="trademark_list"
        :type="'trademark'"
        :title="'商标注册申请服务'"
        :more="'更多商标服务'"
      />
    </div>
    <div class="home-hotpatent">
      <Title :title="title_list" :more="'热门商标交易'" />
      <div class="item-father">
        <div
          v-for="(item, index) in trademark_trade"
          :key="index + 'hot'"
          class="hot-item"
        >
          <div class="header">
            <div class="left">
              <img :src="item.imgurl" alt="" />
            </div>
            <div class="right">
              <div class="title">{{ item.title }}</div>
              <div class="text">
                {{ item.text }}
              </div>
            </div>
          </div>
          <div class="footer">
            <div class="price">
              <span class="sign">￥</span>
              <span class="money">{{ item.price }}</span>
            </div>
            <div class="btn" @click="showModel(item)">购买咨询</div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-copyright-registration">
      <div class="header">
        <!-- <Title :title="copyright" :more="'版权登记服务'" /> -->
        <Title :more="'版权登记服务'" />
        <Service
          :copyright="$store.state.home.copyrightList.serviceList"
          :type="'copyrightList'"
        />
      </div>
      <div class="innovation">
        <div class="title">创新服务</div>
        <CopyrightRegistration
          :copyright="$store.state.home.innovationList.serviceList"
          :type="'innovationList'"
        />
      </div>
    </div>
    <div class="home-intellectual-property">
      <div class="title">知产动态</div>
      <div class="intellectual-tab">
        <div
          class="intellectual-item"
          v-for="(item, index) in newsList"
          :key="index + 'property'"
          @click="toNews(item)"
        >
          <div class="left">
            <div class="date">
              {{ item.createdDate.substring(0, 10).split("-")[1] }}<span>·</span
              >{{ item.createdDate.substring(0, 10).split("-")[2] }}
            </div>
            <div class="years">
              {{ item.createdDate.substring(0, 10).split("-")[0] }}
            </div>
          </div>
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <div class="text" v-html="item.desp"></div>
          </div>
        </div>
      </div>
      <div @click="$router.push('/news')" class="footer">
        <span>更多动态</span>
      </div>
    </div>
    <div class="home-footer">
      <div class="footer-tab">
        <div class="title">知识产权综合服务平台</div>
        <div class="btn" @click="$refs.msgtype.visible = true">立即咨询</div>
      </div>
    </div>
    <Floating
      :consultant="
        JSON.parse($store.state.user.web_data.default_consultant.value)
      "
    />
    <MsgModel ref="msgtype" />
    <Model ref="model" />
    <Footer />
  </div>
</template>
 
<script>
import PatentApplication from "../comon/PatentApplication.vue";
import Floating from "../../comon/Floating.vue";
import Service from "../comon/Service.vue";
import CopyrightRegistration from "../comon/CopyrightRegistration.vue";
import Model from "../../comon/model.vue";
import Title from "../comon/Title.vue";
import MsgModel from "../../comon/MsgModel.vue";
import { news } from "../../api/index";
import Footer from "../../comon/Footer.vue";
export default {
  name: "Home",
  components: {
    PatentApplication,
    CopyrightRegistration,
    Service,
    Title,
    Footer,
    Model,
    Floating,
    MsgModel,
  },
  data() {
    return {
      newsList: [],
      // copyright: [
      //   {
      //     title: "保护知识成果，遇侵权有据追诉",
      //   },
      //   {
      //     title: "授权他人使用，无风险实现商业盈利",
      //   },
      //   {
      //     title: "高新企业认证条件，获政策补贴",
      //   },
      // ],
      patent_list: [
        {
          title: "保护知识成果，遇侵权有据追诉",
        },
        {
          title: "可转让/授权他人使用，无风险实现商业盈利",
        },
        {
          title: "高新企业认证条件，获政策补贴",
        },
      ],
      tab_list: [
        {
          title: "发明专利",
          list: [
            {
              title: "一种太阳能光伏电池安装装置",
              patentText: "2020108430592",
              price: 24000,
              category: 1,
            },
            {
              title: "适用于新能源汽车的盘式制动装置",
              patentText: "2019104108249",
              price: 15000,
              category: 1,
            },
            {
              title: "材料腐蚀速率与壁面润湿特性测试系统",
              patentText: "2018114744119",
              price: 35000.0,
              category: 1,
            },
            {
              title:
                "一种氟烷基羧烃基聚硅氧烷/纳米粒子超疏水性复合膜及其制备方法",
              patentText: "2012101286168",
              price: 35000.0,
              category: 1,
            },
            {
              title: "一种用于纺织业的具有干燥功能的羊绒梳理除杂装置",
              patentText: "2019101629723",
              price: 14000.0,
              category: 1,
            },
            {
              title: "一种桥梁伸缩缝快速清理装置",
              patentText: "2019105816273",
              price: 14000.0,
              category: 1,
            },
          ],
        },
        {
          title: "实用新型专利",
          list: [
            {
              title: "一种园林建设用的草坪修整机",
              patentText: "2019224542927",
              price: 900.0,
              category: 1,
            },
            {
              title: "一种密封效果好的环境保护用污液回收装置",
              patentText: "201921953737X",
              price: 900.0,
              category: 1,
            },
            {
              title: "一种具有散热结构的固态电解质电容器",
              patentText: "202021920387X",
              price: 900.0,
              category: 1,
            },
            {
              title: "一种便于安装的ATSE用传动机构",
              patentText: "2020220075745",
              price: 900.0,
              category: 1,
            },
            {
              title: "便于拆装可重复回收利用的发光二极管",
              patentText: "2020220387501",
              price: 900.0,
              category: 1,
            },
            {
              title: "一种配件加工用夹持装置",
              patentText: "2020217750078",
              price: 900.0,
              category: 1,
            },
          ],
        },
      ],
      title_list: [
        {
          title: "优质专利资源",
        },
        {
          title: "平台保障更安心",
        },
      ],
      trademark_trade: [
        {
          title: "第05类 - 医疗用品",
          text: "医用益生菌制剂,维生素软糖,营养补充剂,蛋白质膳食补充剂,刺激益生菌生长的膳食补充剂,维生素制剂,人用药,鱼肝油,膳食纤维,医用营养品",
          imgurl: require("../../assets/service/ykx.jpg"),
          price: 10200,
          category: 2,
        },
        {
          title: "第29类 - 肉食蜜饯",
          text: "加工过的花生,加工过的瓜子,肉,加工过的坚果,肉脯,糖炒栗子,盐焗腰果,加工过的核桃,豆腐制品,食用面筋",
          price: 10800,
          imgurl: require("../../assets/service/jjl.jpg"),
          category: 2,
        },
        {
          title: "第04类 - 化学原料",
          text: "工业用油,汽油,润滑剂,润滑油,燃料,液体燃料,无烟煤,蜡（原料）,照明用蜡,电能",
          imgurl: require("../../assets/service/px.jpg"),
          price: 26400,
          category: 2,
        },
        {
          title: "第03类 - 日化用品",
          text: "洁肤乳液,洗面奶,清洁制剂,擦亮用剂,香精油,美容面膜,化妆品,爽肤水,牙膏,香",
          imgurl: require("../../assets/service/fh.jpg"),
          price: 19200,
          category: 2,
        },
        {
          title: "第06类 - 金属材料",
          text: "金属片和金属板,金属管,金属门,金属窗,紧线夹头,五金器具,家具用金属附件,门用金属附件,金属锁（非电）,保险柜",
          imgurl: require("../../assets/service/lys.jpg"),
          price: 14760,
          category: 2,
        },
        {
          title: "第28类 - 健身器材",
          text: "玩具,游戏器具,棋,球拍,锻炼身体器械,体育活动器械,游泳池（娱乐用品）,保护垫（运动服部件）,轮滑鞋,钓鱼用具",
          imgurl: require("../../assets/service/jwbb.jpg"),
          price: 27600,
          category: 2,
        },
      ],
      trademark_list: [
        {
          title: "市场通行证",
        },
        {
          title: "品牌保护伞",
        },
        {
          title: "重要无形资产",
        },
      ],
    };
  },
  created() {
    this.getNews();
  },
  methods: {
    toNews(item) {
      this.$router.push({
        path: "/news",
        query: {
          item: item,
        },
      });
    },
    async getNews() {
      const params = {
        pageNum: 1,
        pageSize: 10,
        isRec: 1,
      };
      const res = await news(params);
      if (res.code === 200) {
        this.newsList = res.data.list;
      }
    },
    showModel(item) {
      this.$refs.model.visible = true;
      this.$store.state.home.modalParms = item;
    },
    callback(key) {
      console.log(key);
    },
  },
};
</script>

<style lang='less'>
@import "../../less/home.less";
</style>
